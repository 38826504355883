.font-size
{
    font-size: 27px;
    font-weight: 600;
    display: block;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 11px;
    margin: auto
}